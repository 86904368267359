import React from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Box } from "@mui/material";
import LanguageSwitcher from './LanguageSwitcher';
import { IconButton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import BarChartIcon from '@mui/icons-material/BarChart';
import { useTranslation } from "react-i18next";

const Header = () => {
  const { t } = useTranslation();
  return (
    <AppBar position="static">
      <Toolbar>
        <IconButton
            edge="start"
            color="inherit"
            aria-label={t("Home")}
            component={Link}
            to="/"
          >
          <HomeIcon />
        </IconButton>
        <Typography variant="h6" edge="start" style={{ flexGrow: 1 }}>
          {t("Page Title")}
        </Typography>
        <nav>
          <IconButton
            color="inherit"
            aria-label={t("Statistics")}
            component={Link}
            to="/stats"
          >
            <BarChartIcon />
            <Typography variant="body1" component="span" color="inherit">
              {t("Statistics")}
            </Typography>
          </IconButton>
        </nav>
        <Box>
          <LanguageSwitcher />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
