import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { DirectionProvider } from './DirectionProvider';
import Dashboard from './components/Dashboard';
import StatsPage from './components/StatsPage';
import SearchResults from './components/SearchResults';
import KeyDetails from './components/KeyDetails';
import MainLayout from './components/MainLayout';

function App() {
  return (
    <Router>
      <DirectionProvider>
        <main>
          <Routes>
            <Route exact path="/" element={<Dashboard />} />
            <Route path="/stats" element={<StatsPage />} />
            <Route path="/search/:searchTerm" element={<MainLayout><SearchResults /></MainLayout>} />
            <Route path="/details/:keyId" element={<MainLayout><KeyDetails /></MainLayout>} />
          </Routes>
        </main>
      </DirectionProvider>
    </Router>
  );
}

export default App;
