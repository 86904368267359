import React, { useState } from 'react';

function UIDsPopover({ keyId, uids }) {
  const [showPopover, setShowPopover] = useState(false);

  const togglePopover = () => {
    setShowPopover(!showPopover);
  };

  return (
    <div>
      {uids.length > 0 && (
        <button onClick={togglePopover}>Show UIDs</button>
      )}
      {showPopover && (
        <div className="popover">
          <ul>
            {uids.map((uid, index) => (
              <li key={`${keyId}_${index}`}>{uid.uid}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
}

export default UIDsPopover;
