import { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { pksLookup } from '../controllers/api.js'
import ResultsTable from "./ResultsTable";


function SearchResults() {
  const { searchTerm } = useParams();
  const [pksLookupResults, setPksLookupResults] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const fetchData = useCallback(async (searchTerm) => {
    const deb = await pksLookup({op: 'index', options: 'mr'}, searchTerm);
    if (deb) {
      setPksLookupResults(deb);
    } else {
      NotificationManager.error('Error searching for keys');
      setPksLookupResults([]);
    }
  }, []);

  useEffect(() => {
    if (searchTerm) {
      fetchData(searchTerm);
    }
  }, [searchTerm, fetchData]);
    

  return (
    <>
      {pksLookupResults.results && pksLookupResults.results.length > 0 && (
        <ResultsTable
          results={pksLookupResults.results}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
    </>
  );
}

export default SearchResults;
