import React, { useState } from "react";
import { Box, Typography, Grid, Link, Modal, Backdrop, Fade, Container } from "@mui/material";
import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const [openAbout, setOpenAbout] = useState(false);

  const handleOpenAbout = () => {
    setOpenAbout(true);
  };

  const handleCloseAbout = () => {
    setOpenAbout(false);
  };

  return (
    <Box mt={5} py={2} borderTop="1px solid #ccc">
      <Container maxWidth="lg">
        <Box display="flex" justifyContent="left" alignItems="left" spacing={2}>
          <Grid mt={1}>
            <Grid item  xs={2}>
              <Typography>
                <Link href="#" onClick={handleOpenAbout}>
                  {t("About")}
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Container>
      <Modal
        open={openAbout}
        onClose={handleCloseAbout}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openAbout}>
          <Box
            style={{
              backgroundColor: "white",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "20px",
              outline: "none",
              width: "50%",
              margin: "auto",
              marginTop: "10%",
            }}
          >
            <Typography variant="h5" gutterBottom>
              {t("About")}
            </Typography>
            <Typography variant="body1">
              This is a sample application that demonstrates the functionality of advanced search and key management. It provides options for users to upload PGP keys and customize search options to suit their needs.
            </Typography>
          </Box>
        </Fade>
      </Modal>
    </Box>
  );
}

export default Footer;
