import React from 'react';
import { CssBaseline } from "@mui/material";
import MainLayout from "./MainLayout";
import PageTitle from "./PageTitle";
import PgpSearchForm from "./PgpSearchForm";

const Dashboard = () => {
  // You can reuse the code from the Hockeypuck server stats application here

  return (
    <>
      <PageTitle titleKey="Page Title" />
      <CssBaseline />
      <MainLayout>
        <PgpSearchForm />
      </MainLayout>
    </>
  );
};

export default Dashboard;
