import { useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormGroup,
  Grid,
  Input,
  InputLabel,
  TextField,
  Typography
} from "@mui/material";
import { useTranslation } from "react-i18next";
import AdvancedSearchOptions from "./AdvancedSearchOptions";
import { NotificationManager } from 'react-notifications';
import HKP from '@openpgp/hkp-client';
import { pksLookup } from '../controllers/api.js'
import ResultsTable from "./ResultsTable";

const openpgp = require("openpgp");

const hkpClient = new HKP('https://keyserver.dobrev.it'); // Replace with the keyserver URL you want to use

function PgpSearchForm() {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState("");
  const [showUploadKey, setShowUploadKey] = useState(false);
  const [pgpKey, setPgpKey] = useState("");
  const [checkBoxes, setCheckBoxes] = useState({
    fingerprint: {
        val: true, label: t("Show OpenPGP fingerprints")
    },
    hash: {
        val: false, label: t("Show full-key hashes")
    },
    exact: {
        val: false, label: t("Only return exact matches")
    },
    mr: {
        val: false, label: t("Machine readable")
    },
  });
  const [selectedRadio, setSelectedRadio] = useState('index');
  const [searchResults, setSearchResults] = useState([]);
  //const [filteredResults, setFilteredResults] = useState([]);
  const [subkeyDisplay, setSubkeyDisplay] = useState({});
  const [pksLookupResults, setPksLookupResults] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleCheckboxChange = (event) => {
    setCheckBoxes({
        ...checkBoxes,
        [event.target.name]: {
          ...checkBoxes[event.target.name],
          val: event.target.checked,
        },
      });
  };

  const handleIndexTypeChange = (event) => {
    setSelectedRadio(event.target.value);
  };

  const handleUploadKeyOpen = () => {
    setShowUploadKey(true);
  };

  const handleUploadKeyClose = () => {
    setShowUploadKey(false);
  };

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const deb = await pksLookup({op: selectedRadio, options: 'mr'}, searchTerm);
    if (deb) {
      setPksLookupResults(deb);
    } else {
      NotificationManager.error('Error searching for keys');
      setPksLookupResults([]);
    }

    try {
      const results = await hkpClient.lookup({
        query: searchTerm
      });
      setSearchResults(results);
      //setFilteredResults(results);
    } catch (error) {
      NotificationManager.error('Error searching for keys:' + error);
      setSearchResults([]);
      //setFilteredResults([]);
    }
    if (searchResults) {
      const publicKeys = await openpgp.readKeys({
        armoredKeys: searchResults
      });
      const subkeys = {};
      publicKeys.forEach((key) => {
        subkeys[key.getKeyId().toHex()] = key.getKeys();
      });
      setSubkeyDisplay(subkeys);
    }

    console.log("Submitting form", searchTerm, checkBoxes, selectedRadio, searchResults, subkeyDisplay);
    // Your code to submit the form here
  };

  const readUploadedFileAsText = async (inputFile) => {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException("Error parsing input file."));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsText(inputFile);
    });
  };

  const handlePgpKeyChange = (event) => {
    setPgpKey(event.target.value);
  };

  const handleSubmitPgpKey = async (event) => {
    event.preventDefault();

    try {
      const keyData = await openpgp.readKey({ armoredKey: pgpKey });

      if (keyData.length === 0) {
        NotificationManager.error("Invalid PGP key");
        return;
      }

      // Now you have a valid PGP key, you can upload it to the server
      console.log("Uploading armoured key:", keyData);
      try {
        await hkpClient.upload(pgpKey);
        NotificationManager.success("Uploading key: " + keyData, "Success", 5000);
        setShowUploadKey(false);
      } catch (error) {
        NotificationManager.error("Error uploading the PGP key");
      }
    } catch (error) {
      NotificationManager.error("Error processing the PGP key");
    }
  };

  const handleUpload = async (event) => {
    event.preventDefault();
    const file = event.target.files[0];

    if (!file) {
      NotificationManager.error("Please select a file");
      return;
    }

    try {
      const fileContent = await readUploadedFileAsText(file);
      const keyData = await openpgp.readKey({ armoredKey: fileContent });

      if (keyData.length === 0) {
        NotificationManager.error("Invalid PGP key file");
        return;
      }

      // Now you have a valid PGP key, you can upload it to the server
      try {
        await hkpClient.upload(fileContent);
        NotificationManager.success("Uploading key: " + keyData, "Success", 5000);
        setShowUploadKey(false);
      } catch (error) {
        NotificationManager.error("Error uploading key: " + error, "Error", 5000);
      }
    } catch (error) {
      NotificationManager.error("Error processing the PGP key file: " + error, "Error", 5000);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center'
      }}
    >
      <FormControl component="form" onSubmit={handleSubmit}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="left"
          marginBottom="2rem"
          width="100%"
        >
          <Typography variant="h4" component="h1" gutterBottom>
            <strong>{t("Hockeypuck")}</strong> {t("OpenPGP Keyserver")}
          </Typography>
          <FormGroup sx={{ width: '100%', maxWidth: '30em' }}>
            <TextField
              label={t("Search for an OpenPGP Public Key, ie 0x...")}
              placeholder={t("Search for an OpenPGP Public Key, ie 0x...")}
              variant="outlined"
              value={searchTerm}
              onChange={handleInputChange}
              required
              autoFocus
            />
          </FormGroup>
          <FormGroup sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
            <Grid container justifyContent="center" spacing={2}>
              <Grid item>
                <Button variant="contained" color="primary" type="submit">
                {t("Search Key")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleUploadKeyOpen}
                >
                  {t("Submit Key")}
                </Button>
              </Grid>
            </Grid>
            <Box display="flex" flexDirection="row" justifyContent="space-between">
              <AdvancedSearchOptions
                checkBoxes={checkBoxes}
                handleCheckboxChange={handleCheckboxChange}
                selectedRadio={selectedRadio}
                handleIndexTypeChange={handleIndexTypeChange}
              />
            </Box>
          </FormGroup>
        </Box>
      </FormControl>
      {pksLookupResults.results && pksLookupResults.results.length > 0 && (
        <ResultsTable
          results={pksLookupResults.results}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      <Dialog open={showUploadKey} onClose={handleUploadKeyClose} fullWidth>
        <DialogContent>
          <FormControl fullWidth>
            <Box display="flex" justifyContent="center" alignItems="center" spacing={2}>
              <InputLabel
                htmlFor="key-upload"
              >
                {t("Upload Key")}
              </InputLabel>
              <Input
                id="key-upload"
                type="file"
                onChange={handleUpload}
                sx={{ marginTop: '1em' }}
                width="100%"
              />
              </Box>
          </FormControl>
          <Box display="flex" justifyContent="center" alignItems="center" my={2}>
            <Typography variant="h6">OR</Typography>
          </Box>
          <Box
            sx={{ marginTop: '1em' }}
            display="flex"
            flexDirection="column"
            alignItems="center"
            textAlign="left"
            width="100%"
            spacing={2}
          >
            <TextField
              label={t("Paste PGP Armored Key")}
              multiline
              rows={10}
              variant="outlined"
              value={pgpKey}
              onChange={handlePgpKeyChange}
              fullWidth
            />
            <Button
              onClick={handleSubmitPgpKey}
              color="primary"
              variant="contained"
              sx={{ marginTop: '1em' }}
            >
              {t("Submit PGP Key")}
            </Button>
            <Button
              onClick={handleUploadKeyClose}
              color="primary"
              sx={{ marginTop: '1em' }}
            >
              {t("Close")}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default PgpSearchForm;
