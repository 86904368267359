import React, { useState, useEffect } from 'react';
import { NotificationManager } from 'react-notifications';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  Tab,
  Tabs,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { pksLookup } from '../controllers/api.js'

const openpgp = require('openpgp');

function convertToHex(str) {
  var hex = '';
  for(var i=0;i<str.length;i++) {
      hex += ''+str.charCodeAt(i).toString(16);
  }
  return hex;
}

function KeyDetails() {
  const { t } = useTranslation();
  const { keyId } = useParams();
  const [keyDetails, setKeyDetails] = useState(null);
  const [pgpKey, setPgpKey] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);


  const handleTabChange = (event, newValue) => {
    event.preventDefault();
    setTabIndex(newValue);
  };

  const handleDownloadKey = () => {
    if (!keyDetails) {
      return;
    }
    const element = document.createElement('a');
    const file = new Blob([keyDetails], { type: 'text/plain' });
    element.href = URL.createObjectURL(file);
    element.download = `key-${keyId}.asc`;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const handleCopyToClipboard = () => {
    if (!keyDetails) {
      return;
    }
    navigator.clipboard.writeText(keyDetails).then(() => {
      NotificationManager.success(t('Copied to clipboard'), t('Success'), 2000);
    }, (err) => {
      console.error('Could not copy text: ', err);
    });
  };

  useEffect(() => {
    const fetchKey = async (keyId) => {
      var response, pgpKey;
      try {
        response = await pksLookup({op: 'get', options: 'mr'}, keyId);
      } catch (error) {
        NotificationManager.error(error.message, 'Error', 5000);
        return;
      }

      try {
        pgpKey = await openpgp.readKeys({ armoredKeys: response });
      } catch (error) {
        NotificationManager.error(error.message, 'Error', 5000);
        return;
      }

      setPgpKey(pgpKey);
      setKeyDetails(response);
    };

    fetchKey(keyId);
  }, [keyId]);

  if (!keyDetails) {
    return (<p>{t("Key not found")}</p>)
  }

  return (
    <Box>
      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label={t('Details')} />
        <Tab label={t('Armored Key')} />
      </Tabs>
      {tabIndex === 0 && (
        <>
          {pgpKey && pgpKey.map((key, index) => (
            <Accordion key={index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  Key ID: {convertToHex(key.keyPacket.keyID.bytes)}, Created: {key.keyPacket.created.toLocaleString()}, Algorithm: {key.keyPacket.algorithm}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* Users section */}
                <div>
                  <Typography variant="h6">UIDs</Typography>
                  <List>
                    {key.users.map((user, idx) => (
                      <ListItem key={`${user.userID.name}_${idx}`}>
                        <ListItemText primary={`${user.userID.userID}`} />
                      </ListItem>
                    ))}
                  </List>
                </div>
                {/* Subkeys section */}
                <div>
                  <Typography variant="h6">Subkeys</Typography>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Subkey ID</TableCell>
                          <TableCell>Created</TableCell>
                          <TableCell>Algorithm</TableCell>
                          {/* Add more columns if needed */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {key.subkeys.map((subkey, idx) => (
                          <TableRow key={idx}>
                            <TableCell>
                              <Link to={`/details/0x${convertToHex(subkey.keyPacket.keyID.bytes)}`}>{convertToHex(subkey.keyPacket.keyID.bytes)}</Link>
                            </TableCell>
                            <TableCell>{subkey.keyPacket.created.toLocaleString()}</TableCell>
                            <TableCell>{subkey.keyPacket.algorithm}</TableCell>
                            {/* Add more cells if needed */}
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                {/* Other sections for directSignatures and revocationSignatures */}
                {/* ... */}
              </AccordionDetails>
            </Accordion>
          ))}
        </>
      )}
      {tabIndex === 1 && (
        <Box>
          <Box
            component="pre"
            sx={{
              whiteSpace: 'pre-wrap',
              fontFamily: 'monospace',
              fontSize: '1rem',
              border: '1px solid',
              borderColor: 'divider',
              borderRadius: 1,
              padding: 2,
              maxHeight: '300px',
              overflowY: 'auto'
            }}
          >
            {keyDetails} {/* Replace this with the actual armored key string */}
          </Box>
          <Box mt={2}>
            <Button variant="outlined" color="primary" onClick={handleCopyToClipboard}>
              {t('Copy to Clipboard')}
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleDownloadKey}>
              {t('Download Key')}
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default KeyDetails;
