import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from 'react-i18next';

function convertToHex(str) {
  var hex = '';
  for(var i=0;i<str.length;i++) {
      hex += ''+str.charCodeAt(i).toString(16);
  }
  return hex;
}

const KeyDetailsModal = ({ open, onClose, keyDetails }) => {
  const { t } = useTranslation();
  if (!Array.isArray(keyDetails) || keyDetails.length === 0) return null;
  console.log(keyDetails);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{t("Key Details")}</DialogTitle>
      <DialogContent>
        {keyDetails.map((key, index) => (
          <Accordion key={index}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>
                Key ID: {convertToHex(key.keyPacket.keyID.bytes)}, Created: {key.keyPacket.created.toLocaleString()}, Algorithm: {key.keyPacket.algorithm}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* Users section */}
              <div>
                <Typography variant="h6">Users</Typography>
                <List>
                  {key.users.map((user, idx) => (
                    <ListItem key={`${user.userID.name}_${idx}`}>
                      <ListItemText primary={`${user.userID.name} ${user.userID.comment} <${user.userID.email}>`} />
                    </ListItem>
                  ))}
                </List>
              </div>
              {/* Subkeys section */}
              <div>
                <Typography variant="h6">Subkeys</Typography>
                <TableContainer>
                  <Table size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>Subkey ID</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Algorithm</TableCell>
                        {/* Add more columns if needed */}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {key.subkeys.map((subkey, idx) => (
                        <TableRow key={idx}>
                          <TableCell>{convertToHex(subkey.keyPacket.keyID.bytes)}</TableCell>
                          <TableCell>{subkey.keyPacket.created.toLocaleString()}</TableCell>
                          <TableCell>{subkey.keyPacket.algorithm}</TableCell>
                          {/* Add more cells if needed */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {/* Other sections for directSignatures and revocationSignatures */}
              {/* ... */}
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("Close")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default KeyDetailsModal;
