// This file contains the API calls for the app
import axios from "axios";

const algoMapping = {
  1: 'RSA',
  16: 'ElGamal',
  17: 'DSA',
  18: 'ECDH',
  19: 'ECDSA',
  20: 'EdDSA',
  // Add more mappings if needed
};

function ParseMachineReadableIndex(data) {
  const lines = data.trim().split('\n');
  const numResults = parseInt(lines[0].split(':')[2], 10);
  let results = [];
  let currentResult = null;

  for (const line of lines.slice(1)) {
    const fields = line.split(':');
    const recordType = fields[0];

    if (recordType === 'pub') {
      if (currentResult) {
        results.push(currentResult);
      }
      currentResult = {
        keyId: fields[1],
        algo: algoMapping[parseInt(fields[2], 10)] || fields[2],
        keylen: parseInt(fields[3], 10),
        creationDate: parseInt(fields[4], 10),
        expirationDate: parseInt(fields[5], 10) || null,
        flags: fields[6],
        uids: [],
      };
    } else if (recordType === 'uid') {
      if (currentResult) {
        currentResult.uids.push({
          uid: fields[1],
          creationDate: parseInt(fields[2], 10),
        });
      }
    }
  }

  if (currentResult) {
    results.push(currentResult);
  }

  return { numResults, results };
}

// Set REQUEST_HOST based on the current domain
const { protocol, host } = window.location;

export const REQUEST_HOST = `${protocol}//${host}`;

export async function pksLookup(options, search) {
  if (!options.op || !options.options) {
    return null;
  }

  const isLocalhost = host.includes("localhost") || host.includes("192.168.") || host.includes("mui.keyserver.dobrev.it");
  const apiHost = isLocalhost ? "https://keyserver.dobrev.it" : REQUEST_HOST;

  if (["index", "vindex", "get", "hget"].includes(options.op)) {
    const response = await axios.get(`${apiHost}/pks/lookup?op=${options.op}&options=${options.options}&search=${search}`);
    if (response.status === 200) {
      if (options.options === "mr" && ["index", "vindex"].includes(options.op)) {
        return ParseMachineReadableIndex(response.data);
      }
      return response.data;
    } else {
      console.log("Error", response.status, response.statusText);
      // return sensible error
      return null;
    }
  } else if (options.op === "stats") {
    const response = await axios.get(`${apiHost}/pks/lookup?op=${options.op}&options=${options.options}`);
    if (response.status === 200) {
      return response.data;
    } else {
      console.log("Error", response.status, response.statusText);
      // return sensible error
      return null;
    }
  }
}

export async function fetchPgpData() {
  const response = await pksLookup({op: 'stats', options: 'mr'});
  return response;
}
