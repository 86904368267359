import React, { useState } from "react";
import { Box, Collapse, Button, FormControlLabel, Checkbox, Radio, RadioGroup, FormControl, FormLabel } from "@mui/material";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function AdvancedSearchOptions({ checkBoxes, handleCheckboxChange, selectedRadio, handleIndexTypeChange}) {
  const { t } = useTranslation();
  const [showOptions, setShowOptions] = useState(false);

  const handleToggleOptions = () => {
    setShowOptions(!showOptions);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
      <Button onClick={handleToggleOptions}>{showOptions ? <KeyboardArrowUp /> : <KeyboardArrowDown />}{t("Advanced Options")}</Button>
      <Collapse in={showOptions}>
        <Box mt={2} xs={12} sm={6} md={4}>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("Index options:")}</FormLabel>
            {Object.entries(checkBoxes).map(([key, value]) => (
                <FormControlLabel
                    key={key}
                    control={<Checkbox checked={value.val} onChange={handleCheckboxChange} name={key} />}
                    label={value.label}
                />
            ))}
          </FormControl>
          <FormControl component="fieldset">
            <FormLabel component="legend">{t("Index type:")}</FormLabel>
            <RadioGroup
              aria-label="indexType"
              name="indexType"
              value={selectedRadio}
              onChange={handleIndexTypeChange}
            >
              <FormControlLabel value="index"  control={<Radio />} label={t("Get regular index of matching keys")} />
              <FormControlLabel value="vindex" control={<Radio />} label={t("Get verbose index of matching keys")} />
              <FormControlLabel value="get"    control={<Radio />} label={t("Retrieve ascii-armored keys")} />
              <FormControlLabel value="hget"   control={<Radio />} label={t("Retrieve keys by full-key hash")} />
            </RadioGroup>
          </FormControl>
        </Box>
      </Collapse>
    </Box>
  );
}

export default AdvancedSearchOptions;
