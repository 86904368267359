import PageTitle from "./PageTitle";
import MainLayout from "./MainLayout";
import StatsLayout from "./StatsLayout";
import { CssBaseline } from "@mui/material";

function StatsPage() {
  return (
    <>
      <PageTitle titleKey="Page Title" />
      <CssBaseline />
      <MainLayout>
        <StatsLayout />
      </MainLayout>
    </>
  );
}

export default StatsPage;
