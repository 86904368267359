import React from 'react';
import UIDsPopover from './UIDsPopover';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TablePagination, Paper } from '@mui/material';
import './UIDsPopover.css'
import { useTranslation } from 'react-i18next';
import HKP from '@openpgp/hkp-client';
import KeyDetailsModal from './KeyDetailsModal';

function ResultsTable({ results, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage }) {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedKey, setSelectedKey] = React.useState(null);

  const openpgp = require("openpgp");
  const hkpClient = new HKP('https://keyserver.dobrev.it'); // Replace with the keyserver URL you want to use

  const handleOpenModal = async (keyId) => {
    // Fetch the key details using openpgp readKeys function
    // Assuming you have the key as a string or object, set it to the state
    const lookup = await hkpClient.lookup({ query: keyId });
    const keyDetails = await openpgp.readKeys({ armoredKeys: lookup });
    setSelectedKey(keyDetails);
    setOpenModal(true);
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>{t("Key ID")}</TableCell>
            <TableCell>{t("Algorithm")}</TableCell>
            <TableCell>{t("Bit Length")}</TableCell>
            <TableCell>{t("Creation Time")}</TableCell>
            {/* <TableCell>{t("Expiration Time")}</TableCell> */}
            <TableCell>{t("UIDs")}</TableCell>
            <TableCell>{t("Action")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {results.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((result) => (
            <>
            <TableRow key={result.keyId}>
              <TableCell>
                <Button onClick={() => handleOpenModal('0x' + result.keyId)}>0x{result.keyId}</Button>
              </TableCell>
              <TableCell>{result.algo}</TableCell>
              <TableCell>{result.keylen}</TableCell>
              <TableCell>{new Date(result.creationDate * 1000).toLocaleDateString()}</TableCell>
              {/* <TableCell>{result.expirationDate ? new Date(result.expirationDate * 1000).toLocaleDateString() : '-'}</TableCell> */}
              <TableCell>
                <UIDsPopover uids={result.uids} keyId={results.keyId} />
              </TableCell>
              <TableCell>
                {/* Add the action button(s) here */}
              </TableCell>
            </TableRow>
            <KeyDetailsModal
              open={openModal}
              onClose={() => setOpenModal(false)}
              keyDetails={selectedKey}
            />
            </>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={results.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton={true}
        showLastButton={true}
        rowsPerPageOptions={[5, 10, 15, 20]}
      />
    </TableContainer>
  );
}

export default ResultsTable;
